import './rule-details.css';
import {PriceThresholdRuleDto} from "@coma/api/rules/dto/price.threshold.rule.dto";
import React from "react";
import {PriceThresholdRule} from "./price-threshold-rule/price-threshold-rule";
import {RuleDetailsProps} from "./rule-details.props";
import {Error, Header, Switch} from "../../../components";
import {PriceDiffRuleDto, RuleDto, RuleType, TextRuleDto, serviceRulesApi} from "@coma/api/rules";
import useServiceApi from "../../../hooks/useServiceApi";
import {PriceDiffRule} from "./price-diff-rule/price-diff-rule";
import {TwitterRule} from "./twitter-rule/twitter-rule";

/**
 * Generic rule
 */
export const RuleDetails = function RuleDetailsComponent(props: RuleDetailsProps) {
    const { rule, isFastEdit, onReload, onBack, onLogout } = props
    const {
        execute: executeUpdateRule,
        waiting: waitingUpdateRule,
        error: errorUpdateRule
    } = useServiceApi<RuleDto>(serviceRulesApi.updateRule);

    const onUpdateRule = (update: Partial<TextRuleDto | PriceDiffRuleDto>, waitingString: string) => {
        executeUpdateRule(update, waitingString)
            .then(() => {
                onReload();
            })
    }

    return <div className={"rule-details"}>
        {
            errorUpdateRule ?
                <>
                    {errorUpdateRule &&
                    <Error errorTx={errorUpdateRule} tryAgainAction={executeUpdateRule} onLogout={onLogout}/>}
                </>
                :
                <>
                    <Header title="Rules" onBack={onBack}/>
                    {
                        rule.type === RuleType.PRICE_DIFF
                        && <>
                            {getSmsComponent(rule, isFastEdit, onUpdateRule, waitingUpdateRule)}
                            <PriceDiffRule rule={rule as PriceDiffRuleDto} isFastEdit={isFastEdit} updateRule={onUpdateRule} waiting={waitingUpdateRule}/>
                        </>
                    }
                    {
                        rule.type === RuleType.TEXT
                        && <>
                            {getSmsComponent(rule, isFastEdit, onUpdateRule, waitingUpdateRule)}
                            <TwitterRule rule={rule as TextRuleDto} updateRule={onUpdateRule} waiting={waitingUpdateRule}/>
                        </>
                    }
                    {
                        rule.type === RuleType.PRICE_THRESHOLD
                        && <>
                            {getSmsComponent(rule, isFastEdit, onUpdateRule, waitingUpdateRule)}
                            <PriceThresholdRule rule={rule as PriceThresholdRuleDto} isFastEdit={isFastEdit} updateRule={onUpdateRule} waiting={waitingUpdateRule}/>
                        </>
                    }
                </>
        }
    </div>
}

const getSmsComponent = (rule: RuleDto,
                         isFastEdit: boolean,
                         updateRule: (update: Partial<TextRuleDto | PriceDiffRuleDto>, waitingString: string) => void,
                         waiting: string) => {
    return <>
        <Switch labelTx={"Enable sms"}
                disabled={!isFastEdit}
                waiting={waiting === "enable-sms"}
                checked={rule.sendSms}
                onChange={(checked) => {
                    updateRule(
                        {
                            id: rule.id,
                            sendSms: checked,
                        },
                        "enable-sms",
                    );
                }}
        />
        <div className={"content__splitter"}/>
    </>
}
