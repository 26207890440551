import './account-edit.css';
import React from "react";
import {AccountEditProps} from "./account-edit.props";
import {serviceUserApi, UserDto} from "@coma/api/users";
import useServiceApi from "../../../hooks/useServiceApi";
import {Error, Header, Switch} from "../../../components";
import {AccountDetails} from "../../account/account-details/account-details";

/**
 * Account management
 */
export const AccountEdit = function AccountEditComponent(props: AccountEditProps) {
    const { account, onUpdate, onBack, onLogout } = props
    const {
        execute: executeUpdateUser,
        waiting: waitingUpdateUser,
        error: errorUpdateUser
    } = useServiceApi<UserDto>(serviceUserApi.updateUser);

    const onUpdateUser = (update: Partial<UserDto>, waitingString: string) => {
        executeUpdateUser(update, waitingString)
            .then((updatedAccount) => {
                onUpdate(updatedAccount);
            })
    }

    return <div className={"account-edit"}>
        {
            errorUpdateUser ?
                <Error errorTx={errorUpdateUser} tryAgainAction={executeUpdateUser} onLogout={onLogout}/>
                :
                <>
                    <Header title="Accounts" onBack={onBack}/>
                    {
                        <Switch labelTx={"Account enabled"}
                                waiting={waitingUpdateUser === "account-enabled"}
                                checked={!account.disabled}
                                onChange={(checked) => {
                                    onUpdateUser(
                                        {
                                            id: account.id,
                                            disabled: checked ? "" : "true",
                                        },
                                        "account-enabled",
                                    );
                                }}
                        />
                    }
                    <div className={"content__splitter"}/>
                    <AccountDetails account={account}/>
                </>
        }
    </div>
}
