import './dashboard.css';
import {SocialPlatforms} from "../../modules/social.platforms/social.platforms";
import {DashboardModules} from "./dashboard.modules";
import {DashboardProps} from "./dashboard.props";
import React, {useState} from "react";
import {
    ShowChartSharp,
    MessageSharp,
    NotificationsSharp,
    RuleSharp
} from '@mui/icons-material';
import {Exchanges, Notifications, Rules} from "../../modules";
import {Button} from "../../components";

/**
 * Welcome page
 */
export const Dashboard = function DashboardPage(props: DashboardProps) {
    const { defaultModule, autoRefreshEnabled, smsEnabled, licenseType, showToolbar = true, setActiveAppModule, onLogout, children } = props;
    const [ openModule, setOpenModule ] = useState<DashboardModules>(defaultModule)
    const [ loadCount, setLoadCount ] = useState<number>(0)

    const onOpenRulesModule = () => {
        setOpenModule(DashboardModules.RULES);
        setLoadCount(loadCount + 1);
    }
    const onOpenExchangesModule = () => {
        setOpenModule(DashboardModules.EXCHANGES);
        setLoadCount(loadCount + 1);
    }
    const onOpenNotificationsModule = () => {
        setOpenModule(DashboardModules.NOTIFICATIONS);
        setLoadCount(loadCount + 1);
    }
    const onOpenSocialMessagesModule = () => {
        setOpenModule(DashboardModules.SOCIAL_MESSAGES);
        setLoadCount(loadCount + 1);
    }

    return <div className={"dashboard"}>
        {
            showToolbar
            && <div className={"dashboard__toolbar"}>
                <Button icon={RuleSharp} onClick={onOpenRulesModule}
                        highlight={openModule === DashboardModules.RULES}
                        blink={autoRefreshEnabled && openModule === DashboardModules.RULES}
                />
                <Button icon={ShowChartSharp} onClick={onOpenExchangesModule}
                        highlight={openModule === DashboardModules.EXCHANGES}
                        blink={autoRefreshEnabled && openModule === DashboardModules.EXCHANGES}
                />
                <Button icon={NotificationsSharp} onClick={onOpenNotificationsModule}
                        highlight={openModule === DashboardModules.NOTIFICATIONS}
                        blink={autoRefreshEnabled && openModule === DashboardModules.NOTIFICATIONS}
                />
                <Button icon={MessageSharp} onClick={onOpenSocialMessagesModule}
                        highlight={openModule === DashboardModules.SOCIAL_MESSAGES}
                        blink={autoRefreshEnabled && openModule === DashboardModules.SOCIAL_MESSAGES}
                />
            </div>
        }
        <div className={"dashboard__content"}>
            {children}
            {openModule === DashboardModules.RULES ?
                <Rules loadCount={loadCount}
                       smsEnabled={smsEnabled}
                       autoRefreshEnabled={autoRefreshEnabled}
                       setActiveAppModule={setActiveAppModule}
                       onLogout={onLogout}
                 />
                : undefined
            }
            {openModule === DashboardModules.EXCHANGES ?
                <Exchanges loadCount={loadCount}
                           autoRefreshEnabled={autoRefreshEnabled}
                           licenseType={licenseType}
                           setActiveAppModule={setActiveAppModule}
                           onLogout={onLogout}
                />
                : undefined
            }
            {openModule === DashboardModules.NOTIFICATIONS ?
                <Notifications loadCount={loadCount}
                               autoRefreshEnabled={autoRefreshEnabled}
                               setActiveAppModule={setActiveAppModule}
                               onLogout={onLogout}
                />
                : undefined
            }
            {openModule === DashboardModules.SOCIAL_MESSAGES ?
                <SocialPlatforms loadCount={loadCount}
                                 autoRefreshEnabled={autoRefreshEnabled}
                                 setActiveAppModule={setActiveAppModule}
                                 onLogout={onLogout}
                />
                : undefined
            }
        </div>
    </div>
}
