import {AxiosRequestConfig} from "axios";
import {axios_get, axios_post} from "../../common";
import {RuleDto} from "../../rules";
import {Coin} from "../dto/coin";
import {ExchangePricesDto} from "../dto/exchange.prices.dto";

export const EXCHANGES_PRICES_ROUTE = "/exchanges-prices"

export const EXCHANGES_PRICES_ADAPTED_VALUE = "/exchanges-prices-adapted-value"

class ExchangeApi {

    getExchangesPrices(): Promise<ExchangePricesDto[]> {
        const config: AxiosRequestConfig = {};
        return axios_get(EXCHANGES_PRICES_ROUTE, config);
    }

    calculateAdaptedValue(params: {coin: Coin,  quantity: number}): Promise<number> {
        const config: AxiosRequestConfig = {};
        return axios_post(EXCHANGES_PRICES_ADAPTED_VALUE, params, config, mapToAdaptedValue);
    }
}

const mapToAdaptedValue = (data: any): RuleDto => {
    return data.adaptedValue;
}

export const serviceExchangeApi = new ExchangeApi();
