import './notifications.css';
import {NotificationsProps} from "./notifications.props";
import React, {useCallback, useEffect, useState} from "react";
import {DeleteSharp} from "@mui/icons-material";
import {NotificationDto, serviceNotificationsApi} from "@coma/api/notifications";
import {AppModules} from "../app.modules";
import useServiceApi from "../../hooks/useServiceApi";
import {EmptyList, Error, IconButton, IconButtonSize, ProgressIndicator} from "../../components";

/**
 * Notifications module
 */
export const Notifications = function NotificationsModule(props: NotificationsProps) {
    const { loadCount, setActiveAppModule, autoRefreshEnabled, onLogout } = props;
    const [ notifications, setNotifications ] = useState<NotificationDto[]>();
    const {
        execute: executeGetNotifications,
        waiting: waitingGetNotifications,
        error: errorGetNotifications
    } = useServiceApi<NotificationDto[]>(serviceNotificationsApi.getNotifications);
    const {
        execute: executeDeleteNotifications,
        waiting: waitingDeleteNotifications,
        error: errorDeleteNotifications
    } = useServiceApi<void>(serviceNotificationsApi.deleteNotifications);

    const getNotifications = useCallback(() => {
        executeGetNotifications()
            .then((notificationsDto) => {
                setNotifications(notificationsDto);
            })
    }, [ executeGetNotifications ])

    const deleteNotifications = useCallback(() => {
        executeDeleteNotifications().then(() => getNotifications())
    }, [ executeDeleteNotifications, getNotifications ])

    useEffect(() => {
        setActiveAppModule(AppModules.NOTIFICATIONS);
    }, [ setActiveAppModule, loadCount ]);

    useEffect(() => {
        getNotifications();
        if (autoRefreshEnabled) {
            const interval = setInterval(getNotifications, 1000 * 45);
            return () => clearInterval(interval);
        }
    }, [ autoRefreshEnabled, getNotifications ]);

    return <div className={"notifications"}>
        {waitingGetNotifications ?
            <ProgressIndicator/>
            :
            errorGetNotifications ?
                <>
                    {
                        errorGetNotifications &&
                        <Error errorTx={errorGetNotifications} tryAgainAction={getNotifications} onLogout={onLogout}/>
                    }
                    {
                        errorDeleteNotifications &&
                        <Error errorTx={errorDeleteNotifications} tryAgainAction={executeDeleteNotifications}
                               onLogout={onLogout}/>
                    }
                </>
                :
                notifications && notifications.length ?
                    <>
                        <div className={"notifications__delete"}>
                            <IconButton icon={DeleteSharp}
                                        size={IconButtonSize.LARGE}
                                        waiting={!!waitingDeleteNotifications}
                                        onClick={deleteNotifications}
                            />
                        </div>
                        {
                            notifications.map((notification) => {
                                return <div key={notification.id}>
                                    <div className={"notifications__entry"}>
                                        <div>
                                            {new Date(notification.utcTime).toLocaleString()}
                                        </div>
                                        <div className={"notifications__entry__text"}>
                                            {notification.text}
                                        </div>
                                        <div className={"notifications__entry__state"}>
                                            sms {notification.state.toLowerCase()} at {new Date(notification.stateUtcTime).toLocaleTimeString()}
                                        </div>
                                    </div>
                                    <div className={"content__splitter"}/>
                                </div>
                            })
                        }
                    </>
                    :
                    <EmptyList titleTx={"No notifications..."}/>
        }
    </div>
}
