import './social.platforms.css';
import {serviceSocialPlatformApi, SocialMessageDto} from "@coma/api/social.platforms";
import {SocialPlatformsProps} from "./social.platforms.props";
import React, {useCallback, useEffect, useState} from "react";
import {sanitizeText} from "@coma/api/utils/securityUtils";
import { AppModules } from '../app.modules';
import useServiceApi from "../../hooks/useServiceApi";
import {ALL_KEYWORD_OPTION, EmptyList, Error, getKeywordOptions, ProgressIndicator, Select} from "../../components";

/**
 * Social messages module
 */
export const SocialPlatforms = function SocialPlatformsModule(props: SocialPlatformsProps) {
    const { loadCount, autoRefreshEnabled, setActiveAppModule, onLogout } = props;
    const [ socialMessages, setSocialMessages ] = useState<SocialMessageDto[]>();
    const [ keyword, setKeyword ] = useState<string>(ALL_KEYWORD_OPTION);
    const {
        execute: executeGetSocialMessages,
        waiting: waitingGetSocialMessages,
        error: errorGetSocialMessages
    } = useServiceApi<SocialMessageDto[]>(serviceSocialPlatformApi.getSocialMessages);

    const getSocialMessages = useCallback(() => {
        executeGetSocialMessages()
            .then((socialMessageDto) => {
                setSocialMessages(socialMessageDto);
            })
    }, [ executeGetSocialMessages ])

    useEffect(() => {
        setActiveAppModule(AppModules.SOCIAL_MESSAGES);
    }, [ setActiveAppModule, loadCount ]);

    useEffect(() => {
        getSocialMessages();
        if (autoRefreshEnabled) {
            const interval = setInterval(getSocialMessages, 1000 * 60);
            return () => clearInterval(interval);
        }
    }, [ autoRefreshEnabled, getSocialMessages ]);

    return <div className={"socialMessages"}>
        {waitingGetSocialMessages ?
            <ProgressIndicator/>
            :
            errorGetSocialMessages ?
                <Error errorTx={errorGetSocialMessages} tryAgainAction={getSocialMessages} onLogout={onLogout}/>
                :
                socialMessages && socialMessages.length ?
                    <>
                        <Select labelTx={"Keyword filter"}
                                value={keyword}
                                options={getKeywordOptions(socialMessages)}
                                onChange={(selectedKeyword: string) => setKeyword(selectedKeyword)}
                        />
                        <div className={"content__splitter"}/>
                        {
                            socialMessages.map((socialMessage) => {
                                if (keyword !== ALL_KEYWORD_OPTION && keyword !== socialMessage.keyword) {
                                    return undefined;
                                }
                                return <div key={socialMessage.textHash}>
                                    <div className={"socialMessages__entry"}>
                                        <div>
                                            {new Date(socialMessage.utcTime).toLocaleString()}
                                        </div>
                                        <div className={"socialMessages__entry__account"}>
                                            <div>{socialMessage.account}</div>
                                            <div>parsed by: {socialMessage.parsedBy}</div>
                                        </div>
                                        <div className={"socialMessages__entry__text"}
                                             dangerouslySetInnerHTML={{ __html: sanitizeText(socialMessage.text) }}
                                        />
                                    </div>
                                    <div className={"content__splitter"}/>
                                </div>
                            })
                        }
                    </>
                    :
                    <EmptyList titleTx={"No social messages..."}/>
        }
    </div>
}
