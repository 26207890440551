import {Coin, Exchange} from "@coma/api/exchanges";
import {PriceDiffRuleDto, RuleDto, RuleType, serviceRulesApi, TextRuleDto} from "@coma/api/rules";
import {Operation} from "@coma/api/rules/dto/operation";
import {PriceThresholdRuleDto} from "@coma/api/rules/dto/price.threshold.rule.dto";
import {DoubleArrowSharp} from "@mui/icons-material";
import React from "react";
import {Error, Header, IconButton, IconButtonSize} from "../../../components";
import useServiceApi from "../../../hooks/useServiceApi";
import './add-new-rule.css';
import {AddNewRuleProps} from "./add-new-rule.props";

/**
 * Choose new rule
 */
export const AddNewRule = function AddNewRuleComponent(props: AddNewRuleProps) {
    const { onCreatedRule, onBack, onLogout } = props
    const {
        execute: executeAddRule,
        error: errorAddRule
    } = useServiceApi<RuleDto>(serviceRulesApi.addRule);

    const onAddNewRule = (ruleType: RuleType) => {
        let newRule: any;
        switch (ruleType) {
            case RuleType.PRICE_DIFF:
                const pdr: Partial<PriceDiffRuleDto> = {
                    type: RuleType.PRICE_DIFF,
                    sendSms: false,
                    fromCoin: Coin.OP,
                    fromExchange: Exchange.GATEIO,
                    toCoin: Coin.OP,
                    toExchange: Exchange.ANY,
                    priceDiffInPercent: 2,
                };
                newRule = pdr;
                break;
            case RuleType.PRICE_THRESHOLD:
                const ptr: Partial<PriceThresholdRuleDto> = {
                    type: RuleType.PRICE_THRESHOLD,
                    sendSms: false,
                    coin: Coin.OP,
                    exchange: Exchange.ANY,
                    operation: Operation.GREATER,
                    priceThreshold: 0.01,
                };
                newRule = ptr;
                break;
            case RuleType.TEXT:
                const tr: Partial<TextRuleDto> = {
                    type: RuleType.TEXT,
                    sendSms: false,
                    inclusiveKeyWords: [],
                    exclusiveKeyWords: [],
                };
                newRule = tr;
                break;
        }
        executeAddRule(newRule, "add-new-rule")
            .then((rule) => {
                onCreatedRule(rule);
            });
    }

    return <div className={"add-new-rule"}>
        {
            errorAddRule ?
                <>
                    {errorAddRule &&
                        <Error errorTx={errorAddRule} tryAgainAction={executeAddRule} onLogout={onLogout}/>}
                </>
                :
                <>
                    <Header title="Rules" onBack={onBack}/>
                    <div className={"add-new-rule__rule"}>
                        <div>
                            <div className={"add-new-rule__action"}>
                                Create Price Diff Rule
                            </div>
                            <div className={"add-new-rule__rule-description"}>
                                Rule which compares prices between two selected exchanges
                            </div>
                        </div>
                        <IconButton icon={DoubleArrowSharp}
                                    size={IconButtonSize.LARGE}
                                    important
                                    onClick={() => onAddNewRule(RuleType.PRICE_DIFF)}
                        />
                    </div>
                    <div className={"content__splitter"}/>
                    <div className={"add-new-rule__rule"}>
                        <div>
                            <div className={"add-new-rule__action"}>
                                Create Price Threshold Rule
                            </div>
                            <div className={"add-new-rule__rule-description"}>
                                Rule which compares exchange price against the threshold value
                            </div>
                        </div>
                        <IconButton icon={DoubleArrowSharp}
                                    size={IconButtonSize.LARGE}
                                    important
                                    onClick={() => onAddNewRule(RuleType.PRICE_THRESHOLD)}
                        />
                    </div>
                    <div className={"content__splitter"}/>
                    <div className={"add-new-rule__rule"}>
                        <div>
                            <div className={"add-new-rule__action"}>
                                Create Twitter Rule
                            </div>
                            <div className={"add-new-rule__rule-description"}>
                                Rule which checks particular words in each tweet
                            </div>
                        </div>
                        <IconButton icon={DoubleArrowSharp}
                                    size={IconButtonSize.LARGE}
                                    important
                                    onClick={() => onAddNewRule(RuleType.TEXT)}
                        />
                    </div>
                    <div className={"content__splitter"}/>
                </>
        }
    </div>
}
