import {AppModules} from "../../modules/app.modules";

export enum DashboardModules {
    NOTIFICATIONS,
    EXCHANGES,
    RULES,
    SOCIAL_MESSAGES,
}

export const getDashboardModules = (appModule: AppModules): DashboardModules => {
    switch (appModule) {
        case AppModules.RULES:
            return DashboardModules.RULES;
        case AppModules.EXCHANGES:
            return DashboardModules.EXCHANGES;
        case AppModules.NOTIFICATIONS:
            return DashboardModules.NOTIFICATIONS;
        case AppModules.SOCIAL_MESSAGES:
            return DashboardModules.SOCIAL_MESSAGES;
    }

    return DashboardModules.EXCHANGES;
}
