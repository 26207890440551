import axios, {AxiosError, AxiosRequestConfig} from "axios";
import {Server} from "./server";

export const TOKEN_LOCAL_STORAGE_KEY = "token";

const API_LOCAL = "http://localhost:8085";
const API_A = "https://api.greenkoda.com";

let API_BASE_URL = API_A;

const EMPTY_DATA_HANDLER = (data: any) => data;

export const getServerInstance = (): Server => {
    if (API_BASE_URL === API_A) {
        return Server.SERVER_A;
    }
    return Server.SERVER_LOCAL;
}

export const setServerInstance = (server: Server): void => {
    switch (server) {
        case Server.SERVER_A:
            API_BASE_URL = API_A;
            break;
        case Server.SERVER_LOCAL:
            API_BASE_URL = API_LOCAL;
            break;
        default:
            API_BASE_URL = API_LOCAL
    }
    console.log({API_BASE_URL})
}

export const setNextServerInstance = (): void => {
    const server = getServerInstance();
    switch (server) {
        case Server.SERVER_A:
            setServerInstance(Server.SERVER_LOCAL);
            break;
        case Server.SERVER_LOCAL:
            setServerInstance(Server.SERVER_A);
            break;
        default:
            setServerInstance(Server.SERVER_LOCAL);
    }
}

export const axiosSetAllowOrigin = () => {
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
}

export const axiosSetAuthToken = (token: string) => {
    axios.defaults.headers.common['Authorization'] = `${token}`
}

export const axios_get = async (path: string, config: AxiosRequestConfig, dataHandler: (data: any) => any = EMPTY_DATA_HANDLER) => {
    let response;
    try {
        response = await axios.get(API_BASE_URL + path, config);
    } catch (error: any) {
        return errorHandler(error);
    }
    return dataHandler(response.data);
}

export const axios_post = async (path: string, data: any, config: AxiosRequestConfig, dataHandler: (data: any) => any = EMPTY_DATA_HANDLER) => {
    let response;
    try {
        response = await axios.post(API_BASE_URL + path, data, config);
    } catch (error: any) {
        return errorHandler(error);
    }
    return dataHandler(response.data);
}

export const axios_patch = async (path: string, data: any, config: AxiosRequestConfig, dataHandler: (data: any) => any = EMPTY_DATA_HANDLER) => {
    let response;
    try {
        response = await axios.patch(API_BASE_URL + path, data, config);
    } catch (error: any) {
        return errorHandler(error);
    }
    return dataHandler(response.data);
}

export const axios_delete = async (path: string, dataHandler: (data: any) => any = EMPTY_DATA_HANDLER) => {
    let response;
    try {
        response = await axios.delete(API_BASE_URL + path);
    } catch (error: any) {
        return errorHandler(error);
    }
    return dataHandler(response.data);
}

const errorHandler = (error: AxiosError) => {
    console.error({error});
    setNextServerInstance();
    if (error && error.response) {
        if (error.response.data && error.response.data.error) {
            throw new Error(error.response.data.error);
        }
        throw new Error(error.response.statusText);
    }
    throw new Error(`Unknown error!`);
}
