import './exchanges.css';
import {getTimeDiffInHours} from "@coma/api/utils/general-utils";
import {ExchangesProps} from "./exchanges.props";
import React, {useCallback, useEffect, useState} from "react";
import {ExchangePricesDto, serviceExchangeApi} from "@coma/api/exchanges";
import {AppModules} from "../app.modules";
import useServiceApi from "../../hooks/useServiceApi";
import {
    ALL_KEYWORD_OPTION,
    EmptyList,
    Error,
    getKeywordOptions,
    ProgressIndicator,
    Select,
} from "../../components";

/**
 * Exchanges module
 */
export const Exchanges = function ExchangesModule(props: ExchangesProps) {
    const { loadCount, autoRefreshEnabled, licenseType, setActiveAppModule, onLogout } = props;
    const [ exchangesPrices, setExchangesPrices ] = useState<ExchangePricesDto[]>();
    const [ keyword, setKeyword ] = useState<string>(ALL_KEYWORD_OPTION);
    const [ adaptedPrice, setAdaptedPrice ] = useState<any>({min: 0, max: 0, priceDiff: 0});
    const {
        execute: executeGetExchangesPrices,
        waiting: waitingGetExchangesPrices,
        error: errorGetExchangesPrices
    } = useServiceApi<ExchangePricesDto[]>(serviceExchangeApi.getExchangesPrices);

    const getExchangesPrices = useCallback(() => {
        executeGetExchangesPrices()
            .then((exchangesPricesDto) => {
                let min= 99999;
                let max = 0;
                let priceDiff = 0;
                exchangesPricesDto.forEach(ep => {
                    if (ep.minAdaptedPrice < min) {
                        min = ep.minAdaptedPrice;
                    }
                    if (ep.maxAdaptedPrice > max) {
                        max = ep.maxAdaptedPrice;
                    }
                    if (ep.minMaxAdaptedPriceDiff > priceDiff) {
                        priceDiff = ep.minMaxAdaptedPriceDiff;
                    }
                });
                setAdaptedPrice({min, max, priceDiff})
                setExchangesPrices(exchangesPricesDto);
            })
    }, [ executeGetExchangesPrices ])

    useEffect(() => {
        setActiveAppModule(AppModules.EXCHANGES);
    }, [ setActiveAppModule, loadCount ]);

    useEffect(() => {
        getExchangesPrices();
        if (autoRefreshEnabled) {
            const interval = setInterval(getExchangesPrices, 1000 * 30);
            return () => clearInterval(interval);
        }
    }, [ autoRefreshEnabled, getExchangesPrices ]);

    return <div className={"exchanges"}>
        {waitingGetExchangesPrices ?
            <ProgressIndicator/>
            :
            errorGetExchangesPrices ?
                <Error errorTx={errorGetExchangesPrices} tryAgainAction={getExchangesPrices} onLogout={onLogout}/>
                :
                exchangesPrices && exchangesPrices.length ?
                    <>
                        <Select labelTx={"Keyword filter"}
                                value={keyword}
                                options={getKeywordOptions(exchangesPrices)}
                                onChange={(selectedKeyword: string) => setKeyword(selectedKeyword)}
                        />
                        <div className={"exchanges__prices-summary"}>
                            Max adapted price diff: {adaptedPrice.priceDiff?.toFixed(1)}%
                            {exchangesPrices.length ? ` in last ${getTimeDiffInHours(exchangesPrices[0].utcTime, exchangesPrices[exchangesPrices.length - 1].utcTime)} hours` : ""}
                        </div>
                        <div className={"content__splitter"}/>
                        {
                            exchangesPrices.map((exchangePrice) => {
                                if (keyword !== ALL_KEYWORD_OPTION && !exchangePrice.keyword.includes(keyword)) {
                                    return undefined;
                                }
                                return <div key={exchangePrice.utcTime}>
                                    <div className={"exchanges__prices"}>
                                        <div className={"exchanges__adapted-prices__info"}>
                                            Adapted price diff: {exchangePrice.minMaxAdaptedPriceDiff?.toFixed(1)}%
                                        </div>
                                        {
                                            exchangePrice.pricesByCoin.map((priceByCoin) => {
                                                return <div className={"exchanges__prices"} key={priceByCoin.utcTime}>
                                                    <div className={"exchanges__prices__time-column"}>
                                                        <div>
                                                            {priceByCoin.coin}
                                                            {
                                                                priceByCoin.minMaxPriceDiff > 0 && "(" + priceByCoin.minMaxPriceDiff?.toFixed(1) + "%)"
                                                            }
                                                        </div>
                                                        <div className={"exchanges__prices__time"}>
                                                            {new Date(priceByCoin.utcTime).toLocaleTimeString()}
                                                        </div>
                                                    </div>
                                                    <div className={"exchanges__prices__value-column"}>
                                                        {priceByCoin.prices.map((exchangePrice) => {
                                                            const priceOfInterest = priceByCoin.minMaxPriceDiff > 18 && (exchangePrice.price === priceByCoin.minPrice || exchangePrice.price === priceByCoin.maxPrice);
                                                            const adaptedPriceOfInterest = (exchangePrice.adaptedPrice === adaptedPrice.min || exchangePrice.adaptedPrice === adaptedPrice.max);
                                                            const rowClass = priceOfInterest || adaptedPriceOfInterest ? "exchanges__prices__row-of-interest" : "exchanges__prices__row";
                                                            return <div className={rowClass} key={exchangePrice.exchange}>
                                                                <div className={"exchanges__prices__exchange"}>
                                                                    [{exchangePrice.parsedBy}] {exchangePrice.exchange} ({exchangePrice.adaptedPrice?.toFixed(0)}):
                                                                </div>
                                                                {exchangePrice.price}
                                                            </div>
                                                        })}
                                                    </div>
                                                </div>
                                            })
                                        }
                                        <div className={"exchanges__prices__info"}>
                                            license: {licenseType}
                                        </div>
                                    </div>
                                    <div className={"content__splitter"}/>
                                </div>
                            })
                        }
                    </>
                    :
                    <EmptyList titleTx={"No exchanges prices..."}/>
        }
    </div>
}
