export enum Coin {
    ANY = "ANY",
    DEFI = "DEFI",
    SHIDO = "SHIDO",
    OP = "OP",
    INJ = "INJ",
    ARB = "ARB",
    STRK = "STRK",
    MATIC = "MATIC",
    NIZA = "NIZA",
    POKT = "POKT",
}
