import {setNextServerInstance} from "@coma/api/common";
import * as React from 'react';
import {Error} from "../common";

interface IErrorBoundaryProps {
    tryAgainAction: () => void;
    onLogout: () => void;
    children: React.ReactNode;
}

interface IErrorBoundaryState {
    error?: Error;
    errorInfo?: React.ErrorInfo;
}

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
    constructor(props: IErrorBoundaryProps) {
        super(props);
        this.state = { error: undefined, errorInfo: undefined };
    }

    componentDidCatch(error: Error , errorInfo: React.ErrorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error,
            errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        const { tryAgainAction, onLogout } = this.props;
        const {error, errorInfo} = this.state;
        if (errorInfo) {
            const tryAgain = () => {
                this.setState({
                    error: undefined,
                    errorInfo: undefined,
                });
                setNextServerInstance();
                tryAgainAction();
            }
            return (
                <div>
                    <h2 style={{ color: "white" }}>Something went wrong.</h2>
                    <Error errorTx={"Something went wrong."} tryAgainAction={tryAgain} onLogout={onLogout}/>
                    <div style={{ color: "white", whiteSpace: 'pre-wrap' }}>
                        {error && error.toString()}
                        <br />
                        {errorInfo.componentStack}
                    </div>
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}
