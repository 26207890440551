import './rules.css';
import {AddNewRule} from "./add-new-rule/add-new-rule";
import {RulesProps} from "./rules.props";
import React, {useCallback, useEffect, useState} from "react";
import {Snackbar} from "@mui/material";
import {
    AddCircleOutlineSharp,
    DeleteSharp,
    SmsSharp,
    WarningSharp
} from "@mui/icons-material";
import {RuleDto, serviceRulesApi} from "@coma/api/rules";
import {AppModules} from "../app.modules";
import useServiceApi from "../../hooks/useServiceApi";
import {Error, IconButton, IconButtonSize, ProgressIndicator} from "../../components";
import {RuleDetails} from "./rule-details/rule-details";

/**
 * Rules module
 */
export const Rules = function RulesModule(props: RulesProps) {
    const {
        loadCount,
        smsEnabled,
        autoRefreshEnabled,
        setActiveAppModule,
        onLogout,
    } = props;
    const [ snackbarMessage, setSnackbarMessage ] = useState<string>();
    const [ rules, setRules ] = useState<RuleDto[]>([]);
    const [ addNewRule, setAddNewRule ] = useState<boolean>(false);
    const [ selectedRule, setSelectedRule ] = useState<RuleDto>();
    const [ fastEditRule, setFastEditRule ] = useState<boolean>(false);
    const {
        execute: executeGetRules,
        waiting: waitingGetRules,
        error: errorGetRules
    } = useServiceApi<RuleDto[]>(serviceRulesApi.getRules);
    const {
        execute: executeDeleteRule,
        waiting: waitingDeleteRule,
        error: errorDeleteRule
    } = useServiceApi<void>(serviceRulesApi.deleteRule);

    const loadPage = useCallback(() => {
        onCloseRuleDetails();
        executeGetRules()
            .then((ruleDtos) => {
                setRules(ruleDtos);
            })
    }, [ executeGetRules ]);

    useEffect(() => {
        setActiveAppModule(AppModules.RULES);
    }, [ setActiveAppModule, loadCount ]);

    useEffect(() => {
        loadPage();
    }, [ autoRefreshEnabled, loadPage ]);

    const onDeleteRule = (ruleId: number, waiting: string) => {
        executeDeleteRule(ruleId, waiting)
            .then(() => {
                setRules(rules.filter(r => r.id !== ruleId));
            })
    }

    const onCloseRuleDetails = () => {
        setAddNewRule(false);
        setSelectedRule(undefined);
    }

    const onCreatedRule = (createdRule: RuleDto) => {
        rules.push(createdRule)
        setSelectedRule(createdRule);
        setAddNewRule(false);
        setFastEditRule(false);
    }

    return <div className={"rules"}>
        <div className={"rules__content"}>
            {
                waitingGetRules ?
                    <ProgressIndicator/>
                    :
                    errorGetRules || errorDeleteRule ?
                        <>
                            {errorGetRules &&
                                <Error errorTx={errorGetRules} tryAgainAction={loadPage} onLogout={onLogout}/>}
                            {errorDeleteRule &&
                                <Error errorTx={errorDeleteRule} tryAgainAction={loadPage} onLogout={onLogout}/>}
                        </>
                        :
                        <>
                            {
                                addNewRule ?
                                    <AddNewRule onCreatedRule={onCreatedRule} onBack={onCloseRuleDetails}
                                                onLogout={onLogout}/>
                                    :
                                    selectedRule ?
                                        <RuleDetails rule={selectedRule} isFastEdit={fastEditRule}
                                                     onReload={loadPage} onBack={onCloseRuleDetails}
                                                     onLogout={onLogout}/>
                                        :
                                        <>
                                            {rules
                                                && <div className={"rules__list"}>
                                                    {
                                                        rules.map((rule) => {
                                                            return <div key={rule.id}>
                                                                <div className={"rule"}>
                                                                    <div className={"rule__description"}
                                                                         onClick={() => {
                                                                             setSelectedRule(rule)
                                                                             setFastEditRule(true)
                                                                         }}>
                                                                        <div className={"rule__description-domen"}>
                                                                            {rule.domen}
                                                                        </div>
                                                                        <div>
                                                                            {rule.description}
                                                                        </div>
                                                                        <div className={"rule__description-time"}>
                                                                            activated: {new Date(rule.activated).toLocaleString()}
                                                                        </div>
                                                                    </div>
                                                                    <div className={"rule__actions"}>
                                                                        <div>
                                                                            {
                                                                                rule.sendSms && !smsEnabled &&
                                                                                <IconButton icon={WarningSharp}
                                                                                            size={IconButtonSize.SMALL}
                                                                                            warning
                                                                                            noPadding
                                                                                />
                                                                            }
                                                                            {
                                                                                rule.sendSms &&
                                                                                <IconButton icon={SmsSharp}
                                                                                            size={IconButtonSize.SMALL}
                                                                                            important
                                                                                            noPadding
                                                                                />
                                                                            }
                                                                        </div>
                                                                        <IconButton icon={DeleteSharp}
                                                                                    waiting={waitingDeleteRule === `delete-rule-${rule.id}`}
                                                                                    onClick={() => onDeleteRule(rule.id, `delete-rule-${rule.id}`)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className={"content__splitter"}/>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            }
                                            <div className={"rule__add-new"}>
                                                <IconButton icon={AddCircleOutlineSharp}
                                                            onClick={() => setAddNewRule(true)}
                                                            size={IconButtonSize.LARGE}
                                                />
                                            </div>
                                        </>

                            }

                            <Snackbar open={!!snackbarMessage}
                                      message={snackbarMessage}
                                      onClose={() => setSnackbarMessage("")}
                                      autoHideDuration={3000}
                                      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            />
                        </>
            }
        </div>
    </div>
}
