export enum AppModules {
    RULES = "rules",
    EXCHANGES = "exchanges",
    SOCIAL_MESSAGES= "social_messages",
    WEBTEXTS = "webtexts",
    NOTIFICATIONS = "notifications",
    SETTINGS = "settings",
    ACCOUNT = "account",
}

export const getAppModuleHeader = (module: AppModules) => {
    switch (module) {
        case AppModules.RULES:
            return "Event rules";
        case AppModules.EXCHANGES:
            return "Exchange prices";
        case AppModules.SOCIAL_MESSAGES:
            return "Social messages";
        case AppModules.WEBTEXTS:
            return "Web texts";
        case AppModules.NOTIFICATIONS:
            return "Event notifications";
        case AppModules.SETTINGS:
            return "Settings";
        case AppModules.ACCOUNT:
            return "Account";
    }
    // return isMobileBrowser ? "Exchange prices" : "Bitgert Brise Invest";
}
