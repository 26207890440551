import {AxiosRequestConfig} from "axios";
import {axios_get} from "../../common/services/api.configuration";
import {WebTextDto} from "../dto/webtext.dto";

export const WEBTEXT_ROUTE = "/webtext"

class WebtextApi {

    getWebTexts(): Promise<WebTextDto[]> {
        const config: AxiosRequestConfig = {};
        return axios_get(WEBTEXT_ROUTE, config, mapToWebTextDto);
    }
}

const mapToWebTextDto = (data: any): WebTextDto[] => {
    return data;
}

export const serviceWebTextApi = new WebtextApi();
