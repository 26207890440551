import {AxiosRequestConfig} from "axios";
import {axios_get} from "../../common/services/api.configuration";
import {SocialMessageDto} from "../dto/social.message.dto";

export const SOCIAL_MESSAGES_ROUTE = "/social/messages"

class SocialPlatformsApi {

    getSocialMessages(): Promise<SocialMessageDto[]> {
        const config: AxiosRequestConfig = {};
        return axios_get(SOCIAL_MESSAGES_ROUTE, config, mapToSocialMessageDto);
    }
}

const mapToSocialMessageDto = (data: any): SocialMessageDto[] => {
    return data;
}

export const serviceSocialPlatformApi = new SocialPlatformsApi();
