import './accounts.css';
import {DoubleArrowSharp} from "@mui/icons-material";
import {AccountsProps} from "./accounts.props";
import {AccountEdit} from "./account-edit/account-edit";
import React, {useCallback, useEffect, useState} from "react";
import {serviceUserApi, UserDto} from "@coma/api/users";
import useServiceApi from "../../hooks/useServiceApi";
import {Error, Header, IconButton, IconButtonSize, ProgressIndicator} from "../../components";

/**
 * Accounts module
 */
export const Accounts = function AccountsModule(props: AccountsProps) {
    const { onBack, onLogout } = props;
    const [ users, setUsers ] = useState<UserDto[]>([]);
    const [ selectedUser, setSelectedUser ] = useState<UserDto>();
    const {
        execute: executeGetAllUsers,
        waiting: waitingGetAllUsers,
        error: errorGetAllUsers
    } = useServiceApi<UserDto[]>(serviceUserApi.getAllUsers);

    const loadPage = useCallback(() => {
        executeGetAllUsers()
            .then((userDtos) => {
                setUsers(userDtos);
                setSelectedUser(undefined);
            })
    }, [ executeGetAllUsers ]);

    const onCloseAccountDetails = () => {
        setSelectedUser(undefined);
        loadPage();
    }

    useEffect(() => {
        loadPage();
    }, [ loadPage ]);

    return <div className={"accounts"}>
        {
            waitingGetAllUsers ?
                <ProgressIndicator/>
                :
                errorGetAllUsers ?
                    <Error errorTx={errorGetAllUsers} tryAgainAction={loadPage} onLogout={onLogout}/>
                    :
                    <>
                        {
                            selectedUser ?
                                <AccountEdit account={selectedUser}
                                             onUpdate={(updatedAccount) => setSelectedUser(updatedAccount)}
                                             onBack={onCloseAccountDetails}
                                             onLogout={onLogout}/>
                                :
                                <>
                                    {users
                                    && <>
                                        <Header title="Settings" onBack={onBack}/>
                                        <div className={"accounts__list"}>
                                            {
                                                users.map((user) => {
                                                    return <div key={user.id}>
                                                        <div className={"account_item"}>
                                                            <div className={"account_item__description"}
                                                                 onClick={() => setSelectedUser(user)}>
                                                                <div>
                                                                    {user.name}
                                                                </div>
                                                                <div className={"account_item__created-time"}>
                                                                    created: {new Date(user.created).toLocaleString()}
                                                                </div>
                                                                {user.disabled &&
                                                                    <div className={"account_item__created-time"}>
                                                                        disabled: {user.disabled}
                                                                    </div>
                                                                }
                                                            </div>
                                                            <IconButton icon={DoubleArrowSharp}
                                                                        size={IconButtonSize.LARGE}
                                                                        important
                                                                        onClick={() => setSelectedUser(user)}
                                                            />
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </>
                                    }
                                </>

                        }
                    </>
        }
    </div>
}
