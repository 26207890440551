export enum Exchange {
    ANY = "any",
    LBANK = "lbank",
    MEXC = "mexc",
    GATEIO = "gateio",
    KUCOIN = "kucoin",
    BITMART = "bitmart",
    HOUBI = "houbi",
    BYBIT = "bybit",
    DEXTOOLS_ETH = "dextools-eth",
    DEXTOOLS_BNB = "dextools-bnb",
    UNISWAP_ETH = "uniswap-eth",
}
