import './webtexts.css';
import {serviceWebTextApi, WebTextDto} from "@coma/api/webtext";
import {WebTextsProps} from "./webtexts.props";
import React, {useCallback, useEffect, useState} from "react";
import {sanitizeText} from "@coma/api/utils/securityUtils";
import {AppModules, getAppModuleHeader} from '../app.modules';
import useServiceApi from "../../hooks/useServiceApi";
import {
    ALL_KEYWORD_OPTION,
    EmptyList,
    Error,
    getKeywordOptions,
    Header,
    ProgressIndicator,
    Select
} from "../../components";

/**
 * Web texts module
 */
export const WebTexts = function WebTextsModule(props: WebTextsProps) {
    const { autoRefreshEnabled, setActiveAppModule, lastActiveAppModule, onBack, onLogout, children } = props;
    const backTitle = getAppModuleHeader(lastActiveAppModule);
    const [ webTexts, setWebTexts ] = useState<WebTextDto[]>();
    const [ keyword, setKeyword ] = useState<string>(ALL_KEYWORD_OPTION);
    const {
        execute: executeGetWebTexts,
        waiting: waitingGetWebTexts,
        error: errorGetWebTexts
    } = useServiceApi<WebTextDto[]>(serviceWebTextApi.getWebTexts);

    const getWebTexts = useCallback(() => {
        executeGetWebTexts()
            .then((webTextDto) => {
                setWebTexts(webTextDto);
            })
    }, [ executeGetWebTexts ])

    useEffect(() => {
        setActiveAppModule(AppModules.WEBTEXTS);
    }, [ setActiveAppModule ]);

    useEffect(() => {
        getWebTexts();
        if (autoRefreshEnabled) {
            const interval = setInterval(getWebTexts, 1000 * 60);
            return () => clearInterval(interval);
        }
    }, [ autoRefreshEnabled, getWebTexts ]);

    return <div className={"webTexts"}>
        <div className={"webTexts__content"}>
            {
                children ?
                    children
                    : <Header title={backTitle} onBack={onBack}/>
            }
            {waitingGetWebTexts ?
                <ProgressIndicator/>
                :
                errorGetWebTexts ?
                    <Error errorTx={errorGetWebTexts} tryAgainAction={getWebTexts} onLogout={onLogout}/>
                    :
                    webTexts && webTexts.length ?
                        <>
                            <Select labelTx={"Keyword filter"}
                                    value={keyword}
                                    options={getKeywordOptions(webTexts)}
                                    onChange={(selectedKeyword: string) => setKeyword(selectedKeyword)}
                            />
                            <div className={"content__splitter"}/>
                            {
                                webTexts.map((webText) => {
                                    if (keyword !== ALL_KEYWORD_OPTION && keyword !== webText.keyword) {
                                        return undefined;
                                    }
                                    return <div key={webText.textHash}>
                                        <div className={"webTexts__entry"}>
                                            <div>
                                                {new Date(webText.utcTime).toLocaleString()}
                                            </div>
                                            <div className={"webTexts__entry__web"}>
                                                <div>{webText.text}</div>
                                                <div>{webText.topic}</div>
                                            </div>
                                            {
                                                webText.descriptions.map((desc, index) => {
                                                    return <div key={index}>
                                                        <div className={"webTexts__entry__text"}
                                                             dangerouslySetInnerHTML={{ __html: sanitizeText(desc) }}
                                                        />
                                                        <span>---</span>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <div className={"content__splitter"}/>
                                    </div>
                                })
                            }
                        </>
                        :
                        <EmptyList titleTx={"No web texts..."}/>
            }
        </div>
    </div>
}
